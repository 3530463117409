import EmbeddedPlayer from "./MediaWrappers/EmbeddedPlayer";
import mime from "mime-types";
import { Content } from "../../../types/SubmarinedContent";
import ImageWrapper from "./MediaWrappers/ImageWrapper";
import { Box } from "@mui/material";
import DownloadFile from "./MediaWrappers/DownloadFile";
import PDFViewer from "./MediaWrappers/PDFViewer";
import Image from "next/image";
import { FileType, ProjectInfo } from "@customTypes/ProjectInfo";
import { useContext } from "react";
import { UnlockedContext } from "./UnlockedContentContainer";

export interface SingleMediaDisplayProps {
  submarinedContent: Content;
  projectInfo: ProjectInfo;
  uri?: string;
}

const findFileType = (name) => {
  const content_type = mime.lookup(name);
  if (content_type) {
    if (content_type.includes("image")) {
      return "image";
    }
    if (content_type.includes("video")) {
      return "video";
    }
    if (content_type.includes("audio")) {
      return "audio";
    }
    if (content_type.includes("pdf")) {
      return "pdf";
    }
    if (content_type.includes("html")) {
      return "html";
    }
  }
};

const getFileType = (submarinedContent: Content) => {
  return findFileType(submarinedContent.originalname.trim());
};

const SingleMediaDisplay = ({ projectInfo, submarinedContent, uri }: SingleMediaDisplayProps) => {
  const fileType = getFileType(submarinedContent);
  const { gatewayUrl, token } = useContext(UnlockedContext);
  const url = uri
    ? `${gatewayUrl}${uri}?accessToken=${token}`
    : `${gatewayUrl}/ipfs/${submarinedContent.cid}?accessToken=${token}`;
  //uri is used on gallery view. Format: "/ipfs/bafybeieh3ymtfbclshjdlp6mq7npwqo4zzwd6eczdbd3pytxuihfkcs3sq/cat3.jpeg"

  return (
    <Box
      sx={{
        height: "80%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        userSelect: "none",
        paddingTop: projectInfo.type === FileType.Folder ? "2rem" : "10rem",
      }}
    >
      {fileType == "video" || fileType == "audio" ? (
        <EmbeddedPlayer gatewayUrl={gatewayUrl} submarinedContent={submarinedContent} />
      ) : fileType == "image" ? (
        <>
          {!projectInfo?.customizations?.backgroundCid && projectInfo.type !== FileType.Folder && (
            <Image
              src={url}
              fill
              alt="Background Image"
              style={{
                filter: "blur(10px)",
                objectFit: "cover",
                transform: "scale(1.1)",
                position: "absolute",
                zIndex: "1",
                userSelect: "none",
                pointerEvents: "none",
              }}
              draggable={false}
            ></Image>
          )}
          <ImageWrapper url={url} originalName={submarinedContent.originalname} />
        </>
      ) : fileType == "pdf" ? (
        <PDFViewer url={url} />
      ) : fileType == "html" ? (
        <iframe name="iframe" height="100%" width="100%" src={url}></iframe>
      ) : (
        <DownloadFile url={url} />
      )}
    </Box>
  );
};

export default SingleMediaDisplay;
