import React, { ReactFragment, ReactNode, useContext } from "react";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import { Box, CircularProgress, IconButton, Typography, Unstable_Grid2 } from "@mui/material";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { UnlockContext } from "./WithUnlock";

export enum UnlockState {
  Stale = "stale",
  Pending = "pending",
  Success = "success",
  Error = "error",
}

type OkState = { state: UnlockState.Success };
type ErrorState = { state: UnlockState.Error; message: string };
type PendingState = { state: UnlockState.Pending };
type StaleState = { state: UnlockState.Stale };

export type UnlockStateType = OkState | ErrorState | PendingState | StaleState;

export interface UnlockWrapperProps {
  description: ReactNode;
  children: ReactFragment | ReactNode;
}

const UnlockWrapper = ({ description, children }: UnlockWrapperProps) => {
  const { unlockState, setUnlockState } = useContext(UnlockContext);
  const [showError, setShowError] = React.useState(false);
  let content = children;
  const inRef = React.useRef<HTMLDivElement>(null);
  const outRef = React.useRef<HTMLDivElement>(null);
  const nodeRef = UnlockState.Pending ? inRef : outRef;
  if (unlockState.state === UnlockState.Pending) {
    content = <CircularProgress />;
  }
  if (unlockState.state === UnlockState.Success) {
    content = (
      <IconButton
        sx={{
          color: "green",
        }}
      >
        <DoneOutlineIcon
          sx={{
            fontSize: "2rem",
          }}
        />
      </IconButton>
    );
  }

  if (unlockState.state === UnlockState.Error) {
    setTimeout(() => {
      setShowError(true);
    }, 500);
    content = (
      <IconButton
        sx={{
          color: "red",
        }}
        onClick={() => {
          setUnlockState({ state: UnlockState.Stale });
          setShowError(false);
        }}
      >
        <Unstable_Grid2 container direction="column" alignItems="center" justifyContent={"center"}>
          <CancelOutlinedIcon
            sx={{
              fontSize: "2rem",
            }}
          />

          <Typography
            sx={{
              fontSize: "1rem",
              opacity: showError ? 1 : 0,
              fontWeight: 400,
              textAlign: "center",
              display: "inline",
              color: "red",
              transition: "opacity 0.5s ease",
            }}
          >
            {unlockState.message}
          </Typography>
        </Unstable_Grid2>
      </IconButton>
    );
  }
  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Box sx={{ width: "20rem" }}>
        {description}
        <SwitchTransition mode={"out-in"}>
          <CSSTransition
            key={unlockState.state}
            nodeRef={nodeRef}
            addEndListener={(done) => {
              // @ts-ignore
              nodeRef.current.addEventListener("transitionend", done, false);
            }}
            classNames="unlock"
          >
            <div ref={nodeRef} className="button-container" style={{ height: "100%" }}>
              {content}
            </div>
          </CSSTransition>
        </SwitchTransition>
      </Box>
    </Box>
  );
};

export default UnlockWrapper;
