import { UnlockPayload } from "@customTypes/UnlockPayload";
import { UnlockResult } from "@customTypes/UnlockResult";
import { getKy } from "helpers/ky";
import { createContext, Dispatch, SetStateAction, useState } from "react";
import { useAppDispatch } from "store/hooks";
import { addPayload, tryUnlock } from "store/slices/lockSlice";
import { UnlockState, UnlockStateType } from "./UnlockWrapper";

interface OnUnlock {
  (payload: UnlockPayload): Promise<void>;
}
export const UnlockContext = createContext<{
  unlockState: UnlockStateType;
  setUnlockState: Dispatch<SetStateAction<UnlockStateType>>;
}>({} as any);

const withUnlock = (Component) => {
  const MyComp = (props) => {
    const dispatch = useAppDispatch();
    const [unlockState, setUnlockState] = useState<UnlockStateType>({ state: UnlockState.Stale });
    const onUnlock: OnUnlock = async (payload) => {
      setUnlockState({ state: UnlockState.Pending });
      try {
        const shortId = window.location.pathname.split("/")[1];
        if (payload) {
          const ky = getKy();
          try {
            const res = await ky
              .post("/api/v1/dryrun", {
                json: { shortId, unlockPayload: payload },
              })
              .catch(async (err) => {
                const serverMessage = await err.response.json();
                setUnlockState({ state: UnlockState.Error, message: serverMessage.message });
              });
            if (res) {
              const response = await res.json();
              if (isResponseValid(response)) {
                if (response.status === "ok") {
                  // if verify succeeds, store payload in redux and state as "OK"
                  dispatch(addPayload(payload));
                  dispatch(tryUnlock());
                  setUnlockState({ state: UnlockState.Success });
                  return;
                } else {
                  setUnlockState({ state: UnlockState.Error, message: response.message });
                  return;
                }
              }
            }
          } catch (err) {
            console.log({ err });
            const serverMessage = await err.response.json();
            setUnlockState({ state: UnlockState.Error, message: serverMessage });
            return;
          }
        }
        setUnlockState({ state: UnlockState.Error, message: "Invalid payload" });
      } catch (err) {
        console.log({ err });
        setUnlockState({ state: UnlockState.Error, message: err });
      }
    };
    return (
      <UnlockContext.Provider value={{ unlockState, setUnlockState }}>
        <Component onUnlock={onUnlock} {...props} />
      </UnlockContext.Provider>
    );
  };
  MyComp.displayName = "WithUnlock";
  return MyComp;
};

const isResponseValid = (response): response is UnlockResult => {
  return response?.status !== undefined;
};

export default withUnlock;
