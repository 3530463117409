import { Linkable } from "@customTypes/ProjectInfo";
import { Customizations } from "@customTypes/Customizations";
import CustomButton from "components/Content/LockedContent/LockType/CustomButton";
import { ANALYTICS } from "constants/rudderstack_events";

interface CTAButtonProps {
  customizations: Customizations | null;
  cta?: {
    label: string;
    url: string;
  };
  onClick: () => void;
}
const CTAButton = ({ customizations, cta, onClick }: CTAButtonProps) => {
  if (!cta || !cta.label || !cta.url) {
    return null;
  }
  const handleClick = () => {
    window.open(cta.url, "_blank");
    onClick();
  };
  return (
    <CustomButton customizations={customizations} onClick={handleClick}>
      {cta.label}
    </CustomButton>
  );
};
export default CTAButton;
