import { useContext, useEffect, useState } from "react";
import { Content, SubmarinedContent } from "../../../../types/SubmarinedContent";
import { Box, IconButton, useMediaQuery } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
// import SkipNextIcon from "@mui/icons-material/SkipNext";
import { ProjectInfo } from "@customTypes/ProjectInfo";
import SingleMediaDisplay from "../SingleMediaDisplay";
import DragWrapper from "./DragWrapper";
import { MobileStepper } from "@mui/material";
import { ANALYTICS } from "constants/rudderstack_events";
import NextImageCacher from "./NextImageCacher";
import CTAButton from "./CTAButton";
import { useTheme } from "@mui/material/styles";
import { mobileStepperClasses } from "@mui/material/MobileStepper";
import { UnlockedContext } from "../UnlockedContentContainer";
export interface MultipleMediaDisplayProps {
  projectInfo: ProjectInfo;
  submarinedContent: SubmarinedContent;
}

export enum Direction {
  Left = "left",
  Right = "right",
}

const MultipleMediaDisplay = ({ projectInfo, submarinedContent }: MultipleMediaDisplayProps) => {
  const [selectedItem, setSelectedItem] = useState<Content>(submarinedContent.linkables[0]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { gatewayUrl, token } = useContext(UnlockedContext);

  const currentIndex = submarinedContent.linkables.indexOf(selectedItem);
  const isInactive = false;

  useEffect(() => {
    window.rudderanalytics?.track(ANALYTICS.LINKABLE.VIEW, {
      shortId: projectInfo.shortId,
      cid: selectedItem?.cid,
      userId: projectInfo.userId,
      creatorId: projectInfo.userId,
    });
  }, [selectedItem]);

  const selectNewItem = (item) => {
    setSelectedItem(item);
  };

  const nextUrl = submarinedContent.linkables[currentIndex + 1]
    ? `${gatewayUrl}/ipfs/${
        submarinedContent.linkables[currentIndex + 1]?.cid
      }?accessToken=${token}`
    : null;

  const moveToTheNext = () => {
    const nextIndex = currentIndex + 1;
    if (currentIndex !== -1 && nextIndex < submarinedContent.linkables.length) {
      selectNewItem(submarinedContent.linkables[nextIndex]);
    }
  };

  const moveToPrevious = () => {
    const previous = currentIndex - 1;
    if (previous !== -1 && previous < submarinedContent.linkables.length) {
      selectNewItem(submarinedContent.linkables[previous]);
    }
  };

  const onDragComplete = (direction: Direction) => {
    if (direction === Direction.Left) {
      moveToTheNext();
    } else {
      moveToPrevious();
    }
  };

  const sendCtaClickEvent = () => {
    window.rudderanalytics?.track(ANALYTICS.LINKABLE.CLICK, {
      shortId: projectInfo.shortId,
      cid: selectedItem?.cid,
      userId: projectInfo.userId,
      creatorId: projectInfo.userId,
    });
  };
  return (
    <>
      <MobileStepper
        steps={submarinedContent.linkables.length}
        activeStep={currentIndex}
        backButton={undefined}
        nextButton={undefined}
        variant={"dots"}
        position={isMobile ? "top" : "static"}
        sx={{
          zIndex: "3",
          paddingTop: isMobile ? ".5rem" : "6rem",
          margin: "0 auto",
          width: "100%",
          position: "absolute",
          background: "transparent",
          justifyContent: "center",
          [`& .${mobileStepperClasses.dots}`]: {
            display: "flex",
            width: isMobile ? "100%" : "15rem",
            borderRadius: "6px",
          },

          [`& .${mobileStepperClasses.dot}`]: {
            backgroundColor: "white",
            width: `calc(100% / ${submarinedContent.linkables.length})`,
            borderRadius: "6px",
            height: ".3rem",
          },
          [`& .${mobileStepperClasses.dotActive} ~ .${mobileStepperClasses.dot}`]: {
            backgroundColor: "gray",
          },
        }}
      />
      <DragWrapper onDragComplete={onDragComplete}>
        <SingleMediaDisplay projectInfo={projectInfo} submarinedContent={selectedItem} />
        <NextImageCacher url={nextUrl} />
      </DragWrapper>
      {!isMobile && (
        <>
          <Box
            sx={{
              position: "absolute",
              transition: `left ${isInactive ? "3s ease-out" : "0.5s ease-in"}`,
              left: isInactive ? "-5rem" : "0.25rem",
              top: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              borderRadius: "50%",
              width: "2rem",
              height: "2rem",
              marginLeft: "1rem",
            }}
          >
            <IconButton onClick={moveToPrevious}>
              <ArrowBackIcon
                aria-label="previous"
                sx={{
                  color: "black",
                }}
              />
            </IconButton>
          </Box>
          <Box
            sx={{
              position: "absolute",
              transition: `right ${isInactive ? "3s ease-out" : "0.5s ease-in"}`,
              right: isInactive ? "-5rem" : "0.25rem",
              top: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              borderRadius: "50%",
              width: "2rem",
              height: "2rem",
              marginRight: "1rem",
            }}
          >
            <Box sx={{ textAlign: "center", display: "flex" }}>
              <IconButton onClick={moveToTheNext}>
                <ArrowForwardIcon
                  aria-label="next"
                  sx={{
                    color: "black",
                  }}
                />
              </IconButton>
            </Box>
          </Box>
        </>
      )}
      <Box
        sx={{
          position: "absolute",
          bottom: "1rem",
          width: "100%",
        }}
      >
        <Box sx={{ margin: "auto", width: "10rem", marginBottom: "1rem" }}>
          <CTAButton
            customizations={projectInfo.customizations}
            cta={projectInfo.linkables?.[currentIndex]?.cta}
            onClick={sendCtaClickEvent}
          />
        </Box>
      </Box>
    </>
  );
};

export default MultipleMediaDisplay;
