import { ProjectInfo } from "@customTypes/ProjectInfo";

import { BlockchainOptions, UnlockInfo, UnlockTypes } from "../../../types/UnlockInfo";
import { Customizations } from "../../../types/Customizations";

import dynamic from "next/dynamic";
import { Divider, Typography } from "@mui/material";
import { UnlockPayload } from "@customTypes/UnlockPayload";
import NoLock from "./LockType/NoLock";
import { createContext } from "react";

const LocationUnlock = dynamic(() => import("./LockType/Location"));
const Solana = dynamic(() => import("./LockType/Solana"));
const SolanaProvider = dynamic(() => import("./LockType/SolanaProvider"));
const WagmiProvider = dynamic(() => import("./LockType/WagmiProvider"));
const NFT = dynamic(() => import("./LockType/EVM"), { ssr: false }); // useConnect() hook is not SSR compatible
const Twitch = dynamic(() => import("./LockType/Twitch"));
const FlowUnlock = dynamic(() => import("./LockType/Flow"));

type LockTypesContainerProps = {
  projectInfo: ProjectInfo;
  isPreview: boolean;
};

export interface LockTypeProps {
  unlockInfo: UnlockInfo;
  customizations: Customizations | null;
  onUnlock: (payload: UnlockPayload) => void;
  isPreview?: boolean;
}

export const PreviewContext = createContext({
  unlockInfo: {} as UnlockInfo,
  customizations: {} as Customizations | null,
  isPreview: false,
});

const LockTypesContainer = ({ projectInfo, isPreview = false }: LockTypesContainerProps) => {
  // track success payloads here

  const getLockType = (unlockInfo: UnlockInfo) => {
    const type: UnlockTypes = unlockInfo.type;
    switch (type) {
      case "location":
        return <LocationUnlock />;
      case "solana":
        return (
          <SolanaProvider>
            <Solana />
          </SolanaProvider>
        );
      case "flow":
        return <FlowUnlock />;
      case "evm":
        return (
          <WagmiProvider>
            <NFT />
          </WagmiProvider>
        );
      // @ts-ignore
      case "retweet":
        return (
          <Typography sx={{ fontStyle: "italic" }} color="white">
            Twitter unlock is no longer available
          </Typography>
        );
      case "twitch":
        return <Twitch />;

      case "noLock":
        return <NoLock />;
      default:
        return <Typography color="white">Unknown lock type</Typography>;
    }
  };

  if (projectInfo.unlockInfo.length === 1) {
    <PreviewContext.Provider
      value={{
        unlockInfo: projectInfo.unlockInfo[0],
        customizations: projectInfo.customizations,
        isPreview: isPreview,
      }}
    >
      {getLockType(projectInfo.unlockInfo[0])}
    </PreviewContext.Provider>;
  }
  return (
    <>
      {projectInfo.unlockInfo.map((unlockInfo, i) => {
        return (
          <div key={unlockInfo.type}>
            <PreviewContext.Provider
              value={{
                unlockInfo: unlockInfo,
                customizations: projectInfo.customizations,
                isPreview: isPreview,
              }}
            >
              {getLockType(unlockInfo)}
              <Divider
                sx={{
                  border: "0.25px solid rgba(255, 255, 255, 0.25);",
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  display: i !== 1 ? "block" : "none",
                }}
              />
            </PreviewContext.Provider>
          </div>
        );
      })}
    </>
  );
};

export default LockTypesContainer;
