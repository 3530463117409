import { Button, Box, Typography, Unstable_Grid2 } from "@mui/material";
import { useState } from "react";
import { Download } from "@mui/icons-material";

const DownloadFile = ({ url }: { url: string }) => {
  const [jwtExpired, setJwtExpired] = useState<boolean>(false);
  window.setInterval(() => setJwtExpired(true), 60000);

  return (
    <Box sx={{ width: "100%", textAlign: "center", height: "100%", marginTop: "4rem" }}>
      <Typography variant="h3" sx={{ color: "white", marginBottom: "2rem" }}>
        Content Unlocked{" "}
        <span role="img" aria-label="celebrate">
          🎉
        </span>
      </Typography>
      <Button
        disabled={jwtExpired}
        href={url}
        variant="outlined"
        sx={{
          a: { color: "#fff", textDecoration: "none" },
          backgroundColor: "white",
          borderColor: "black",
          color: "black",
        }}
      >
        <Download />
        Click here to download
      </Button>
    </Box>
  );
};
export default DownloadFile;
