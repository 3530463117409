import React, { useContext, useEffect, useState } from "react";
import Pagination from "./Pagination";
import { GalleryInfo, SubmarinedContent } from "../../../types/SubmarinedContent";
import { useAppDispatch } from "../../../store/hooks";
import { setSubmarinedContent } from "../../../store/slices/submarinedContentSlice";
import { getKy } from "../../../helpers/ky";
import SingleMediaDisplay from "./SingleMediaDisplay";
import { useTheme } from "@mui/material/styles";
import { Paper, Unstable_Grid2, Button, Box } from "@mui/material";
import { ProjectInfo } from "@customTypes/ProjectInfo";
import ListView from "./Gallery/ListView";
import { useMediaQuery } from "@mui/material";
import { UnlockedContext } from "./UnlockedContentContainer";

interface GalleryProps {
  galleryInfo: GalleryInfo;
  projectInfo: ProjectInfo;
}

export default function Gallery({ galleryInfo, projectInfo }: GalleryProps) {
  const items = galleryInfo.childContent;
  const [offset, setOffset] = useState(0);
  const [isDisplaying, setIsDisplaying] = useState<boolean>(false);
  const [displayItem, setDisplayItem] = useState(galleryInfo.childContent[0]);
  const [jwtExpired, setJwtExpired] = useState<boolean>(false);
  const theme = useTheme();
  const { token, gatewayUrl } = useContext(UnlockedContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  window.setInterval(() => setJwtExpired(true), 60000);
  const limit = 10;
  const dispatch = useAppDispatch();

  const handlePageChange = async (dir) => {
    let newOffset;
    if (dir === "forward") {
      newOffset = offset + limit;
      setOffset(newOffset);
    } else {
      newOffset = offset - limit;
      if (newOffset < 0) {
        setOffset(0);
        newOffset = 0;
      } else {
        setOffset(newOffset);
      }
    }

    const ky = getKy();

    const res: SubmarinedContent = await ky
      .post(`/api/content`, {
        json: {
          accessToken: token,
          gatewayURL: `${gatewayUrl}${galleryInfo.childContent[0].uri}`,
          offset: newOffset,
          shortId: window.location.pathname.split("/")[1],
        },
      })
      .json();
    console.log({ res });
    if (res.galleryInfo?.childContent.length === 0) {
      setOffset(newOffset - limit);
    } else {
      dispatch(setSubmarinedContent(res));
    }
  };

  const displaySingleMedia = (item) => {
    setIsDisplaying(true);
    setDisplayItem(item);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Paper
        sx={{
          p: (theme) => theme.spacing(3, 0, 3, 0),
          borderRadius: "20px",
          borderColor: "green",
          position: "relative",
          width: isMobile ? "100%" : "70%",
          height: "70%",
          overflow: "scroll",
        }}
      >
        <Unstable_Grid2 container>
          {!isDisplaying ? (
            <ListView onItemPress={displaySingleMedia} content={items} />
          ) : (
            <Box
              sx={{
                height: "100%",
                width: "100%",
                position: "absolute",
              }}
            >
              <SingleMediaDisplay
                submarinedContent={displayItem}
                projectInfo={projectInfo}
                uri={displayItem.uri}
              />
              <Button sx={{ margin: "1rem" }} onClick={() => setIsDisplaying(false)}>
                Back
              </Button>
            </Box>
          )}
        </Unstable_Grid2>
        {galleryInfo.totalItems > items.length && !isDisplaying && !jwtExpired && (
          <Box>
            <Pagination handlePageChange={handlePageChange} />
          </Box>
        )}
      </Paper>
    </Box>
  );
}
