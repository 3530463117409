import { Box } from "@mui/system";
import { useState } from "react";
import { Direction } from "./MultipleMediaDisplay";

const DragWrapper = ({ onDragComplete, children }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [startPosition, setStartPosition] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const handleTouchStart = (event) => {
    setIsDragging(true);
    if (event.type === "touchstart") {
      setStartPosition(event.changedTouches[0].clientX);
    }
  };

  const handleTouchMove = (event) => {
    if (isDragging) {
      if (event.type === "touchmove") {
        const touchPosition = event.changedTouches[0].clientX;
        const touchOffset = touchPosition - startPosition;
        setPosition((prevPosition) => ({
          x: touchOffset,
          y: prevPosition.y,
        }));
      } else {
        setPosition((prevPosition) => ({
          x: prevPosition.x + event.movementX,
          y: prevPosition.y,
        }));
      }
    }
  };

  const handleTouchEnd = (event) => {
    setIsDragging(false);
    if (position.x > minSwipeDistance) {
      onDragComplete(Direction.Right);
    } else if (position.x < -minSwipeDistance) {
      onDragComplete(Direction.Left);
    }
    setPosition({ x: 0, y: 0 });
  };
  if (typeof window === "undefined") return null;
  const minSwipeDistance = window.innerWidth * 0.1; // 10% of the screen width
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        position: "relative",
        transform: `translate(${position.x * 1.25}px, 0px)`,
        transition: "transform 0.3s ease-out, opacity 0.3s ease-out",
      }}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      onMouseDown={handleTouchStart}
      onMouseMove={handleTouchMove}
      onMouseUp={handleTouchEnd}
      onMouseLeave={handleTouchEnd}
    >
      {children}
    </Box>
  );
};

export default DragWrapper;
