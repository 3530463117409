import { Box } from "@mui/material";
import Image from "next/image";

const NextImageCacher = ({ url }) => {
  // pre loads next image but does not render it.
  if (!url) {
    return null;
  }
  return (
    <Box
      sx={{
        position: "relative",
        textAlign: "center",
        marginTop: "auto",
        marginBottom: "auto",
        height: "100%",
        width: "100%",
        display: "none",
      }}
    >
      <Image src={url} alt={""} priority fill draggable={false} />
    </Box>
  );
};

export default NextImageCacher;
