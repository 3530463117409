import { ProjectInfo } from "@customTypes/ProjectInfo";
import { UnlockPayloadNoLock } from "@customTypes/UnlockPayload";
import { Box, Container, Paper, Typography } from "@mui/material";
import { useAppDispatch } from "store/hooks";
import { addPayload, tryUnlock } from "store/slices/lockSlice";
import ThumbnailImage from "../../Form/ThumbnailImage";
import LockTypesContainer from "./LockTypesContainer";

interface LockedContentContainerProps {
  projectInfo: ProjectInfo;
  gatewayUrl: string;
  isPreview: boolean;
}
const LockedContentWrapper = ({
  projectInfo,
  gatewayUrl,
  isPreview,
}: LockedContentContainerProps) => {
  const isNoLock = projectInfo?.unlockInfo[0].type === "noLock" && !projectInfo.unlockInfo[1];

  const dispatch = useAppDispatch();

  if (isNoLock) {
    const payload: UnlockPayloadNoLock = {
      type: "noLock",
    };
    dispatch(addPayload(payload));
    dispatch(tryUnlock());
    if (!isPreview) {
      return null;
    } else {
      return (
        <Box
          sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}
        >
          <Box
            sx={{
              height: "216px",
              width: "216px",
              color: (theme) => theme.palette.primary.contrastText,
              borderRadius: (theme) => theme.shape.borderRadius * 1.5,
              backgroundColor: "#1C1640",
              padding: (theme) => theme.spacing(3),
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontSize: "1.1rem", color: (theme) => theme.palette.primary.contrastText }}
            >
              Content Zone
            </Typography>
            <Typography color="GrayText" variant="subtitle1">
              Your content will appear within this area
            </Typography>
          </Box>
        </Box>
      );
    }
  }
  return (
    <Container
      sx={{
        paddingTop: "8rem",
        paddingRight: "24px",
        paddingLeft: "24px",
        maxWidth: "500px",
        "@media (min-width: 500px)": {
          maxWidth: "500px",
        },
        "@media (min-width: 960px)": {
          maxWidth: "500px",
        },
        "@media (min-width: 1280px)": {
          maxWidth: "500px",
        },
      }}
    >
      <Paper
        elevation={3}
        sx={{
          position: "relative",
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          borderRadius: (theme) => theme.shape.borderRadius * 1.5,
          textAlign: "center",
          padding: (theme) => theme.spacing(8),
          marginBottom: (theme) => theme.spacing(2.5),
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "-50px",
            left: "50%",
            borderRadius: "50%",
            overflow: "hidden",
            width: "100px",
            height: "100px",
            transform: "translateX(-50%)",
          }}
        >
          <ThumbnailImage
            gatewayUrl={gatewayUrl}
            thumbnail={projectInfo?.customizations?.thumbnail}
          />
        </Box>
        <Typography
          variant="h1"
          sx={{
            marginTop: (theme) => theme.spacing(4),
            color: (theme) => theme.palette.primary.contrastText,
          }}
        >
          {projectInfo.name || "Title"}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            padding: (theme) => theme.spacing(1),
            color: (theme) => theme.palette.primary.contrastText,
            opacity: ".75",
          }}
        >
          {projectInfo.description || "Description"}
        </Typography>
        <LockTypesContainer projectInfo={projectInfo} isPreview={isPreview} />
      </Paper>
    </Container>
  );
};

export default LockedContentWrapper;
