import { useAppSelector } from "../../../store/hooks";
import { selectSubmarinedContent } from "../../../store/selectors/submarinedContentSelectors";
import Gallery from "./Gallery";
import SingleMediaDisplay from "./SingleMediaDisplay";
import MultipleMediaDisplay from "./Linkables/MultipleMediaDisplay";
import { createContext } from "react";

export const UnlockedContext = createContext({
  token: "",
  gatewayUrl: "",
});

const UnlockedContentContainer = ({ projectInfo }) => {
  const content = useAppSelector(selectSubmarinedContent);
  if (!content) {
    throw new Error("Missing Submarined Content");
  }

  let component: React.ReactNode;
  if (content.linkables.length > 1) {
    component = <MultipleMediaDisplay projectInfo={projectInfo} submarinedContent={content} />;
  } else {
    content.galleryInfo
      ? (component = <Gallery projectInfo={projectInfo} galleryInfo={content.galleryInfo} />)
      : (component = (
          <SingleMediaDisplay submarinedContent={content.linkables[0]} projectInfo={projectInfo} />
        ));
  }
  return (
    <UnlockedContext.Provider
      value={{
        token: content.token,
        gatewayUrl: content.gateway,
      }}
    >
      {component}
    </UnlockedContext.Provider>
  );
};

export default UnlockedContentContainer;
