import ReactPlayer from "react-player";
import { Box, IconButton, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import PlayButton from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import { useEffect, useRef, useState } from "react";
import { Content } from "@customTypes/SubmarinedContent";
export interface EmbeddedPlayerProps {
  submarinedContent: Content;
  gatewayUrl: string;
}

const EmbeddedPlayer = ({ submarinedContent, gatewayUrl }: EmbeddedPlayerProps) => {
  // remove trailing /iframe and append /manifest/video.m3u8
  // sometimes videoUrl is not available so we fallback to not streaming the video.
  const [isPlaying, setIsPlaying] = useState(false);
  const signedUrl = submarinedContent?.videoUrl || "";
  const urlManifest = signedUrl?.replace(/\/iframe$/, "/manifest/video.m3u8");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const fallbackUrl = `${gatewayUrl}${submarinedContent.uri}?accessToken=${submarinedContent.jwt}`;
  return (
    <Box
      sx={{
        position: "relative",
        width: isMobile ? "100%" : "70%",
        height: "100%",
      }}
    >
      <IconButton
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 1,
          borderRadius: "0",
          color: "white",
          opacity: isPlaying ? 0 : 0.5,
          width: "100%",
          backgroundColor: "rgba(0,0,0,0.5)",
        }}
        onClick={() => {
          setIsPlaying(!isPlaying);
        }}
      >
        <PlayButton
          sx={{
            height: "15rem",
            width: "15rem",
          }}
        />
      </IconButton>
      <ReactPlayer
        onPlay={() => {
          setIsPlaying(true);
        }}
        onPause={() => {
          setIsPlaying(false);
        }}
        onStart={() => {
          setIsPlaying(true);
        }}
        url={urlManifest || fallbackUrl}
        controls={false}
        playing={isPlaying}
        muted={false}
        stopOnUnmount={true}
        width="100%"
        height="100%"
        onError={() => console.log("Cannot play this Media")}
      />
    </Box>
  );
};

export default EmbeddedPlayer;
