import { ProjectInfo } from "@customTypes/ProjectInfo";
import LockedContentWrapper from "./LockedContent/LockedContentContainer";
import Image from "next/image";
import UnlockedContentContainer from "./UnlockedContent/UnlockedContentContainer";
import {
  selectHasUnlockedContent,
  selectIsContentLoading,
} from "../../store/selectors/submarinedContentSelectors";
import Loading from "../Dashboard/Loading";
import { useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useAppSelector } from "../../store/hooks";

export type MainLandingContentProps = {
  projectInfo: null | ProjectInfo;
  gatewayUrl: string;
  isPreview?: boolean;
};

const MainLandingContent = ({
  projectInfo,
  gatewayUrl,
  isPreview = false,
}: MainLandingContentProps) => {
  const hasUnlockedContent = useAppSelector(selectHasUnlockedContent);
  const isLoading = useAppSelector(selectIsContentLoading);
  const isNoLock: Boolean =
    projectInfo?.unlockInfo[0].type === "noLock" && !projectInfo.unlockInfo[1];
  const hasCustomBackground = projectInfo?.customizations?.backgroundCid;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  let content;
  if (hasUnlockedContent) {
    content = <UnlockedContentContainer projectInfo={projectInfo} />;
  } else if (isLoading) {
    isNoLock ? (content = <></>) : (content = <Loading />);
  } else if (projectInfo) {
    content = (
      <LockedContentWrapper
        projectInfo={projectInfo}
        gatewayUrl={gatewayUrl}
        isPreview={isPreview}
      />
    );
  }

  const background =
    (hasUnlockedContent || (isNoLock && !isPreview)
      ? "#1A1A1A"
      : isPreview && !hasCustomBackground
      ? "#6D57FF"
      : "rgba(109, 87, 255, 1)") || "";

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: isMobile ? "1.2rem" : "1rem",
          left: isMobile ? "1rem" : "calc(10% + 10px)",
          zIndex: "3",
        }}
      >
        {projectInfo?.customizations && projectInfo?.customizations.logoCid ? (
          <CustomLogo logo={projectInfo?.customizations.logoCid} gatewayUrl={gatewayUrl} />
        ) : (
          // svg
          !hasCustomBackground && (
            <Image
              src={"/zigzag.svg"}
              alt="Logo"
              width={isMobile ? 40 : 53}
              height={isMobile ? 60 : 71}
            />
          )
        )}
      </Box>

      <Box
        sx={{
          position: "absolute",
          bottom: "2rem",
          right: "2rem",
          zIndex: "3",
          display: hasCustomBackground
            ? "none"
            : {
                xs: "none",
                sm: "block",
              },
        }}
      >
        <Image src={"/pinata_cloud.svg"} alt="Logo" width={17} height={113} />
      </Box>
      <Box
        sx={{
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100%",
          width: "100%",
          paddingBottom: "2rem",
          ...(isPreview && {
            borderRadius: (theme) => theme.shape.borderRadius * 1.5,
            minHeight: "35rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }),
          backgroundColor: background,
          backgroundImage: `url(${getImageUrl(
            projectInfo?.customizations?.backgroundCid,
            gatewayUrl
          )})`,
        }}
      >
        {content}
      </Box>
    </Box>
  );
};

export default MainLandingContent;

export const CustomLogo = ({ logo, gatewayUrl }) => {
  const [ratio, setRatio] = useState(16 / 9); // default to 16:9
  return (
    <Image
      alt="Logo"
      src={getImageUrl(logo, gatewayUrl)}
      width={60}
      height={60 / ratio}
      onLoadingComplete={({ naturalWidth, naturalHeight }) =>
        setRatio(naturalWidth / naturalHeight)
      }
    />
  );
};

const getImageUrl = (imageCidOrUrl, gatewayUrl) => {
  if (!imageCidOrUrl) return "none";
  if (imageCidOrUrl.includes("user/")) {
    return `${process.env.NEXT_PUBLIC_R2_URL}/${imageCidOrUrl}`;
  } else {
    return `${gatewayUrl}/ipfs/${imageCidOrUrl}`;
  }
};
