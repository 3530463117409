import MapIcon from "@mui/icons-material/Map";
import { Box, Typography, Unstable_Grid2 } from "@mui/material";
import { useContext } from "react";
import { UnlockPayloadNoLock } from "../../../../types/UnlockPayload";
import { LockTypeProps, PreviewContext } from "../LockTypesContainer";
import CustomButton from "./CustomButton";
import UnlockWrapper, { UnlockState, UnlockStateType } from "./UnlockWrapper";
import withUnlock, { UnlockContext } from "./WithUnlock";

const LocationUnlock = ({ onUnlock }: LockTypeProps) => {
  const { unlockInfo, customizations } = useContext(PreviewContext);
  const { setUnlockState } = useContext(UnlockContext);
  if (unlockInfo.type !== "noLock") {
    return null;
  }

  const tryUnlock = async () => {
    setUnlockState({ state: UnlockState.Pending });
    const payload: UnlockPayloadNoLock = {
      type: "noLock",
    };
    await onUnlock(payload);
  };

  const description = (
    <>
      <Typography
        variant="h6"
        sx={{
          padding: (theme) => theme.spacing(1),
          color: (theme) => theme.palette.primary.contrastText,
        }}
      >
        Click to access content!
      </Typography>
      <Box
        sx={{
          color: (theme) => theme.palette.primary.main,
          textDecoration: "underline",
        }}
      ></Box>
    </>
  );
  return (
    <UnlockWrapper description={description}>
      <CustomButton onClick={tryUnlock} customizations={customizations}>
        Access
      </CustomButton>
    </UnlockWrapper>
  );
};

export default withUnlock(LocationUnlock);
