import { Download } from "@mui/icons-material";
import { Box, Button, Card, IconButton } from "@mui/material";
import Image from "next/image";
import { useState } from "react";

export interface ImageWrapperProps {
  url: string;
  originalName: string;
  download?: boolean;
}

const ImageWrapper = ({ url, originalName, download }: ImageWrapperProps) => {
  const [hasDownloaded, setHasDownloaded] = useState<boolean>(true);

  const handleLoadingComplete = () => {
    setHasDownloaded(true);
  };
  return (
    <>
      <Box
        sx={{
          position: "relative",
          textAlign: "center",
          marginTop: "auto",
          marginBottom: "auto",
          height: "100%",
          width: "100%",
          zIndex: "2",
        }}
      >
        <Image
          src={url}
          alt={originalName}
          priority
          onLoadingComplete={handleLoadingComplete}
          fill
          draggable={false}
          style={{
            objectFit: "contain",
            opacity: hasDownloaded ? 1 : 0,
            transition: "opacity 0.25s ease-in-out",
          }}
        />
        {download && (
          <IconButton
            href={url}
            sx={{
              backgroundColor: "white",
              borderColor: "black",
              color: "black",
              width: "10em",
              marginTop: "1rem",
            }}
          >
            <Download />
          </IconButton>
        )}
      </Box>
    </>
  );
};

export default ImageWrapper;
