import { Box } from "@mui/material";
import Image from "next/image";
import Pinny from "../../public/UNDERWATER_PINNY_transparency.png";

const ThumbnailImage = ({ gatewayUrl, thumbnail, ...props }) => {
  if (!thumbnail) {
    return (
      <Box
        sx={{
          background: "#121212",
          flexShrink: 0,
          minWidth: "100%",
          minHeight: "100%",
        }}
      >
        <Image
          style={{
            transform: "matrix(-1, 0.04, 0.04, 1, 0, 0)",
            zIndex: 0,
            width: "100.57px",
            height: "134.96px",
          }}
          width={95}
          height={95}
          src={Pinny}
          alt="Submarine Me"
          {...props}
        />
      </Box>
    );
  }
  let url;
  if (thumbnail.includes("blob:")) {
    url = thumbnail;
  } else if (thumbnail.includes("user/")) {
    url = `${process.env.NEXT_PUBLIC_R2_URL}/${thumbnail}`;
  } else {
    url = `${gatewayUrl}/ipfs/${thumbnail}`;
  }
  return (
    <Image
      style={{
        zIndex: 0,
      }}
      fill
      src={url}
      alt="Submarine Me"
      {...props}
    />
  );
};

export default ThumbnailImage;
