import { Customizations } from "./Customizations";
import { UnlockInfo } from "./UnlockInfo";

export interface ProjectInfo {
  name: string;
  description: string;
  unlockInfo: UnlockInfo[];
  customizations: Customizations | null;
  submarineCID: string;
  shortId: string;
  type: FileType;
  linkables: Linkable[];
  userId?: string;
}

export enum FileType {
  File = "file",
  Folder = "folder",
  Linkable = "linkable",
}

export type Linkable = {
  submarine_cid: string;
  order: number;
  cta?: {
    label: string;
    url: string;
  };
};
