export const ANALYTICS = {
  AUTH: {
    SIGN_UP: "AUTH_SIGN_UP",
    LOGIN: "AUTH_LOGIN",
  },
  LINKABLE: {
    VIEW: "LINKABLE_VIEW",
    CLICK: "LINKABLE_CLICK",
  },
  API: {
    CALL: "API_CALL",
  },
  SUBMARINE_CREATION: {
    COMPLETED: "SUBMARINE_CREATION_COMPLETED",
    CONTENT_ADDED: "SUBMARINE_CREATION_CONTENT_ADDED",
    LOCKSCREEN_CONFIGURED: "SUBMARINE_CREATION_LOCKSCREEN_CONFIGURED",
    START: "SUBMARINE_CREATION_START",
    UNLOCK_CONFIGURED: "SUBMARINE_CREATION_UNLOCK_CONFIGURED",
    UNLOCK_SELECTED: "SUBMARINE_CREATION_UNLOCK_SELECTED",
    SHARE_CLICK: "SUBMARINE_CREATION_SHARE_CLICK",
  },
  PLAN: {
    TRIAL_STARTED: "PLAN_TRIAL_STARTED",
  },
};
