import React, { useContext, useEffect, useState } from "react";
import Button, { buttonClasses } from "@mui/material/Button";
import { Customizations } from "../../../../types/Customizations";
import { PreviewContext } from "../LockTypesContainer";

interface CustomButtonProps {
  customizations: Customizations | null;
  children: React.ReactNode;
  onClick: () => void;
  [x: string]: any;
}
const CustomButton = ({ customizations, children, onClick, disabled, rest }: CustomButtonProps) => {
  const { isPreview } = useContext(PreviewContext);
  const style = {
    width: "90%",
    maxWidth: "300px",
    backgroundColor: "#FAFAFA",
    color: "#181818",
    borderRadius: 1000,
  };
  if (customizations?.buttonColor?.hex) {
    style.backgroundColor = customizations.buttonColor.hex;
  }

  if (customizations?.buttonTextColor?.hex) {
    style.color = customizations.buttonTextColor.hex;
  }

  return (
    <Button
      onClick={onClick}
      sx={{
        [`&.${buttonClasses.disabled}`]: {
          cursor: disabled || isPreview ? "not-allowed" : "pointer",
          pointerEvents: disabled || isPreview ? "auto" : "none",
        },
      }}
      style={style}
      disabled={disabled || isPreview}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
