import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import WorkIcon from "@mui/icons-material/Work";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import mime from "mime";

import Image from "@mui/icons-material/Image";
import MusicNote from "@mui/icons-material/MusicNote";
import VideoLabel from "@mui/icons-material/VideoLabel";
import Archive from "@mui/icons-material/Archive";
import GetApp from "@mui/icons-material/GetApp";
import Description from "@mui/icons-material/Description";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import InsertDriveFile from "@mui/icons-material/InsertDriveFile";
import PictureAsPdfOutlined from "@mui/icons-material/PictureAsPdfOutlined";
import InsertDriveFileOutlined from "@mui/icons-material/InsertDriveFileOutlined";
import TableChart from "@mui/icons-material/TableChart";
import TableChartOutlined from "@mui/icons-material/TableChartOutlined";
import Slideshow from "@mui/icons-material/Slideshow";
import SlideshowOutlined from "@mui/icons-material/SlideshowOutlined";
import Notes from "@mui/icons-material/Notes";
import NotesOutlined from "@mui/icons-material/NotesOutlined";
import Code from "@mui/icons-material/Code";
import ArchiveOutlined from "@mui/icons-material/ArchiveOutlined";

const mainThree = ["image", "audio", "video", "zip"];

const ListView = ({ content, onItemPress }) => {
  return (
    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
      {content.map((item) => (
        <ListItem
          sx={{
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
            },
            cursor: "pointer",
          }}
          key={item.originalname}
          onClick={() => onItemPress(item)}
        >
          <ListItemAvatar>
            <Avatar>{getIcon(getName(item.originalname))}</Avatar>
          </ListItemAvatar>
          <ListItemText primary={getName(item.originalname)} />
        </ListItem>
      ))}
    </List>
  );
};

const getIcon = (filename): string => {
  if (!filename) {
    return "";
  }
  const extension = filename.substr(filename.lastIndexOf(".") + 1);
  const type = mime.getType(extension);
  let icon = "";
  if (type && mainThree.includes(type.split("/")?.[0])) {
    icon = iconMapper(type.split("/")[0]);
  } else {
    icon = iconMapper(type);
  }
  return icon;
};

const getName = (name) => {
  const items = name.split("/");
  let fileName = items[items.length - 1];
  return fileName;
  if (fileName.length > 14) {
    fileName = fileName.substr(0, 5) + "..." + fileName.substr(-7);
  }
  return fileName;
};

const iconMapper = (type): string => {
  const map = {
    image: <Image />,
    audio: <MusicNote />,
    video: <VideoLabel />,
    zip: <Archive />,
    "application/pdf": <PictureAsPdf />,
    "application/msword": <Description />,
    "application/vnd.ms-word": <Description />,
    "application/vnd.oasis.opendocument.text": <Description />,
    "application/vnd.openxmlformats-officedocument.wordprocessingml": <Description />,
    "application/vnd.ms-excel": <TableChart />,
    "application/vnd.openxmlformats-officedocument.spreadsheetml": <TableChart />,
    "application/vnd.oasis.opendocument.spreadsheet": <TableChart />,
    "application/vnd.ms-powerpoint": <Slideshow />,
    "application/vnd.openxmlformats-officedocument.presentationml": <Slideshow />,
    "application/vnd.oasis.opendocument.presentation": <Slideshow />,
    "text/plain": <Notes />,
    "text/html": <PictureAsPdfOutlined />,
    "application/json": <Code />,
    "application/gzip": <ArchiveOutlined />,
    "application/zip": <ArchiveOutlined />,
    "application/octet-stream": <InsertDriveFile />,
  };
  return map[type] || <Archive />;
};

export default ListView;
